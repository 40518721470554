.about-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width:100%;
}

.about-content p{
    max-width: 75%;
    text-align: left;
    margin-bottom: 2rem;
}