* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
}
html {
  scroll-behavior: smooth;
  scroll-padding-top: 35px;
}
h1 {
  font-size: 3rem;
  letter-spacing: 1.25rem;
  text-align: center;
  font-family: "Koulen", cursive;
  color: #252e48;
}
h2 {
  font-size: 2.5rem;
  letter-spacing: 1rem;
  text-align: center;
  font-family: "Koulen", cursive;
  color: #252e48;
}
h3 {
  margin-bottom: 5px;
  font-size: 24px;
}
h4 {
  font-size: 1.5rem;
  color: rgb(13, 132, 192);
  font-family: "Koulen", cursive;
  letter-spacing: 0.25rem;
}
.App {
  background-color: #eeeeee;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  padding-left: 35px;
  margin: 50px 0px;
}
.header hr {
  width: 100%;
}
