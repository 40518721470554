.navbar {
  background: #e2e2e2;
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 999;
  opacity: 1;
}

.navbar-logo {
  width: 250px;
  cursor: pointer;
}

.navbar-icon {
  margin-right: 0.5rem;
  max-height: 100px;
  max-width: 200px;
}

.nav-menu {
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  justify-content: start;
}

.nav-item {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  border-bottom: 2px solid transparent;
}

.nav-item:hover {
  border-bottom: 2px solid #080569;
}

.service-btn {
  background-color: #080569;
  color: #fff;
  border-radius: 20px;
  padding: 1px 20px 1px 20px;
  text-decoration: none;
  font-size: 1rem;
  margin-left: 10px;
}
.service-btn:hover {
  background-color: #cccccc;
  color: #080569;
}
.nav-links {
  color: #080569;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  font-size: 1rem;
}

.fa-bars {
  color: #cccccc;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 1240px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #000000;
    left: 0;
    opacity: 1;
    transition: all 0.6s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    color: #cccccc;
    transform: scale(1.2);
    transition: all 0.3s ease;
  }

  .nav-item:hover {
    border: none;
  }

  .nav-item {
    width: 100%;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
  }

  .menu-icon {
    color: #cccccc;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #cccccc;
    font-size: 2rem;
  }

  .nav-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 120px;
  }
  .navbar {
    height: 80px;
  }

  .navbar-logo {
    visibility: hidden;
  }

  .service-btn {
    margin: 10px 0 0 0;
  }
}
