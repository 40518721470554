.form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 30px;
  padding-bottom: 50px;
}


.form-wrapper {
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.form-input {
  width: 60%;
  min-width: 300px;
  max-width: 600px;
  margin-top: 10px;
  line-height: 30px;
  padding-left: 20px;
  box-shadow: 5px 10px 10px 5px #eeeeee;
  border-radius: 10px;
}

.form-submit {
  width: 10%;
  height: 35px;
  margin-top: 25px;
}
.form-text {
  width: 60%;
  margin: 50px;
}
.form-elements {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.descriptionInput {
  margin-bottom: 2rem;
}

@media screen and (max-width: 960px) {
  .form-wrapper {
    flex-direction: column;
  }
  .form-text {
    width: 90%;
  }
}
