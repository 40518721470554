.hero-slider {
  width: 100%;
  height: 500px;
  position: relative;
  overflow: hidden;
}
.slide {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: all 3s ease;
  animation: slide-down 3s ease;
}
.current {
  opacity: 1;
  animation: fade-out 1s ease;
  animation-delay: 9s;
}
@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.arrow {
  z-index: 999;
  position: absolute;
  width: 100%;
  visibility: hidden;
}
.content {
  display: flex;
  flex-direction: column;
  z-index: 2;
  position: absolute;
  top: 10%;
  left: 10%;
  transform: translate(0 -50%);
  animation: slide-down 3s ease;
  animation-fill-mode: forwards;
  visibility: hidden;
  color: #252e48;
  width: 500px;
  margin-bottom: 1rem;
}
.content h1 {
  font-size: 1.5rem;
  margin-bottom: 25px;
}
.content p {
  margin-bottom: 25px;
}
.content hr {
  margin-bottom: 10px;
}
.white-content {
  display: flex;
  flex-direction: column;
  z-index: 2;
  position: absolute;
  top: 10%;
  left: 10%;
  transform: translate(0 -50%);
  animation: slide-down 3s ease;
  animation-fill-mode: forwards;
  visibility: hidden;
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
  color: #fff;
  width: 500px;
  margin-bottom: 1rem;
  border-radius: 10px;
}
.white-content h1 {
  color: #fff;
  font-size: 1.5rem;
  margin-bottom: 25px;
}
.white-content p {
  margin-bottom: 25px;
}
.white-content hr {
  margin-bottom: 10px;
}

@keyframes slide-down {
  0% {
    opacity: 0;
    top: 10%;
  }
  100% {
    opacity: 1;
    visibility: visible;
    top: 30%;
  }
}

@media screen and (min-width: 600px) {
  .slide img {
    width: 100%;
  }
}
.btn {
  text-align: center;
  width: 150px;
  align-self: flex-end;
  cursor: pointer;
}
