.doors {
  width: 95%;
  margin: 0 auto;
  overflow: hidden;
}

.card {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding: 2rem;
  height: 550px;
  margin-bottom: 10px;
  text-align: center;
  /* box-shadow: 1px 3px 3px 1px #b1b1b1; */
  background-image: linear-gradient(
    rgba(255, 255, 255, 0),
    rgba(223, 223, 223, 0.7)
  );
}
.slick-slide > div {
  margin: 0 10px;
}

.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 25px;
}
.prev-btn {
  min-width: 100px;
  color: #031e47;
  cursor: pointer;
  text-align: center;
  border-radius: 8px;
  margin-right: 5px;
  background-color: #ffffff;
  /* box-shadow: 2px 5px 5px 2px #dadadb; */
}
.next-btn {
  min-width: 100px;
  cursor: pointer;
  text-align: center;
  border-radius: 8px;
  background-color: #ffffff;
  /* box-shadow: 2px 5px 5px 2px #dadadb; */
}
.description {
  margin-top: 10px;
  font-size: 12px;
  list-style: none;
  text-align: center;
}
.card-top > img {
  width: 100%;
  max-height: 200px;
  object-fit:contain;
}
.doors-btn{
  padding:5px;
  margin: 25px;
  cursor: pointer;
}
